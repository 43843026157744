import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import Spacer from "../../../components/Spacer"
import {
  DualImageBlock,
  RightDualComponent
} from "../../../components/Grid"
import Carousel from "../../../components/Carousel"
import MobileCarousel from "../../../components/MobileCarousel"
import styles from "./styles.module.scss"

import dgImg from "./images/dg.png"
import whiteboardImg from "./images/whiteboard.png"
import staffoutingImg from "./images/staffouting.png"
import rusImg from "./images/rus.png"
import viewpcImg from "./images/viewpc.png"
import boothmeetingImg from "./images/boothmeeting.png"
import msgIcon from "./images/default.svg";

const carouselData = [
  {
    img: dgImg,
    title: "David Grimes.",
    colour: "purple",
    header: "Founder and Chief Executive Officer",
    copy: "Since he started My Parcel Delivery from his parents’ kitchen table back in 2009, David’s obsession with cutting-edge tech has been the driving force behind the Group’s rapid growth and innovation. He’s won numerous tech, business and entrepreneurship awards, which are on the mantelpiece next to his double Masters from Cambridge."
  },
  {
    img: dgImg,
    title: "John Crowther.",
    colour: "blue",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: dgImg,
    title: "Vicky Pritchard.",
    colour: "pink",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: dgImg,
    title: "Andy Hill.",
    colour: "green",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: dgImg,
    title: "Paul Ramsden.",
    colour: "dark",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  }
]

const ByMagicImages = () => (
  <div className={styles.magicImages}>
    <div className={styles.top}><img src={viewpcImg} alt="people viewing a pc"/></div>
    <div className={styles.left}><img src={rusImg} alt="man laughing"/></div>
    <div className={styles.right}><img src={boothmeetingImg} alt="people eating lunch in a booth"/></div>
  </div>
)

const OurPeople = () => (
  <Layout JobSection>

    <Seo title="Sorted | Our people" keywords={[`People`, `HR`]} />

    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["We're people", "people."]}>
        <p>Without our team, there would be no Sorted. That’s why we put more time and money into our people than anything else. With a diverse, collaborative, growing team of people pushing to better themselves every day; we’re unstoppable.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <Spacer size="medium" sides="topbottom">
      <div className="container-ultrathin">
        <h2 className="text-pink text-center">Our priority</h2>
        <p className="text-center">With an award-winning Tech House, a benefits package like no other and a culture built by our people, it’s clear to see how the happiness, health, development and wellbeing of the Sorted Team is something we take extreme pride in.</p>
      </div>
    </Spacer>

    <div className={styles.genericSpacing}>
      <DualImageBlock img={staffoutingImg} theme="pink" maxHeight={600}>
        <h3>Delivering development.</h3>
        <p>We’ve built our very own personal development programme, which we affectionately call the ‘Getting Sorted Plan’.</p>
        <p>You work closely with your manager to discuss your career and put actions together to build and improve the most important thing; you. Whether you want to hone existing skills, or learn brand new ones.</p>
      </DualImageBlock>
    </div>

    <div className={styles.genericSpacing}>
      <div className="container-large-fluid">
        <RightDualComponent
          rightComponent={<ByMagicImages />}
          theme="purple"
        >
          <div className={styles.magiComponent}>
            <h3>As if by magic.</h3>
            <p>How we do what we do. This sentiment is very important to everyone within the company, no matter which team, department or location they are in.</p>
          </div>
        </RightDualComponent>
      </div>
    </div>

    <DualImageBlock img={whiteboardImg} theme="grey" maxHeight={700}>
      <h3>Values.</h3>
      <p>We like to work with people who embody our brand personality traits:</p>
      <div className={styles.bulletSection}>
        <div className={styles.bulletItem}>
          <img src={msgIcon} className={styles.msgIcon} alt="large"/>
          <div>
            <h4>Smart</h4>
            <p>Building knowledge, prioritising, learning new skills and honing existing ones.</p>
          </div>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} className={styles.msgIcon} alt="large"/>
          <div>
            <h4>Brave</h4>
            <p>Trying new things, making mistakes, being decisive and having the courage of our convictions.</p>
          </div>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} className={styles.msgIcon} alt="large"/>
          <div>
            <h4>Agile</h4>
            <p>Acting fast, changing directions, being open to new opportunities.</p>
          </div>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} className={styles.msgIcon} alt="large"/>
          <div>
            <h4>Precise</h4>
            <p>Being prompt and measured, detailed and specialised. Our risks are calculated and worth taking.</p>
          </div>
        </div>
      </div>
    </DualImageBlock>

    <Spacer size="medium" sides="topbottom">
      <div class="container-ultrathin">
        <h2 className="text-pink text-center">Our Executive Team.</h2>
      </div>
    </Spacer>

    <div className={styles.desktopCarousel}>
      <Carousel
        detail
        copyHeight="260px"
        items={carouselData}
      />
    </div>

    <div className={styles.mobileCarousel}>
      <MobileCarousel
        detail
        items={carouselData}
      />
    </div>
  </Layout>
)

export default OurPeople
